/* eslint-disable no-mixed-spaces-and-tabs */
import ImageButton from "@shared/ImageButton/ImageButton.vue";
import NoResults from "@shared/NoResults/NoResults.vue";
import WordCloudChartDisplay from "@shared/WordCloudChartDisplay/WordCloudChartDisplay.vue";
import BarChartDisplay from "@shared/BarChartDisplay/BarChartDisplay.vue";
import PieChartDisplay from "@shared/PieChartDisplay/PieChartDisplay.vue";
import FilterChipsContainer from "@shared/FilterChipsContainer/FilterChipsContainer.vue";
import Tabs from "@shared/Tabs/Tabs.vue";
import { mapGetters, mapActions, mapMutations, mapState } from "vuex";
import config from "@/helpers/config";
import chartsMixin from "@mixins/chartsMixin";
import searchMixin from "@mixins/searchMixin";

export default {
  name: "Summary",
  mixins: [chartsMixin, searchMixin],

  components: {
    ImageButton,
    WordCloudChartDisplay,
    Tabs,
    FilterChipsContainer,
    BarChartDisplay,
    PieChartDisplay,
    NoResults,
  },

  data() {
    return {
      apiUrl: config.apiUrl,
      imagesButton: {},
      articlesButton: {},
      filterChipsData: {},
      showFilterChipsContainer: false,
      hasNoResults: false,
      searchValue: "",

      tabNames: [
        "رسم البيان الزمني (المقالات)",
        "رسم البيان الزمني (الصور)",
        "رسم تبويب المادة",
        "رسم تنوع المادة",
        "رسم مصادر التبویب",
        "رسم مصادر الصور",
        "رسم الكلمات الدالة",
        "رسم المؤلفين",
        "رسم المصورين",
      ],

      pieChartColors: [
        "#DA8824",
        "#00A7E1",
        "#60269E",
        "#00A887",
        "#D41367",
        "#DAA900",
        "#0047BA",
        "#76777A",
        "#8C827A",
        "#F29100",
        "#29C3EB",
        "#9164CC",
        "#2ED9C3",
        "#F45197",
        "#FFD100",
        "#5887DA",
        "#98989A",
        "#B5ADA5",
        "#FFAD5F",
        "#B4E7FB",
      ],

      dateStatistics: {
        id: "dateStatistics",
        content: "Lorem ipsum dolor sit amet",
        datasets: {
          labels: [],
          datasets: [],
        },
      },

      articlesDateStatistics: {
        id: "articlesDateStatistics",
        content:
          "عدد المقالات الصحافية المطابقة لعملية البحث على امتداد الفترة المحددة ضمنها",
        datasets: {
          labels: [],
          datasets: [],
        },
      },

      imagesDateStatistics: {
        id: "imagesDateStatistics",
        content:
          "عدد الصور الفوتوغرافية المطابقة لعملية البحث على امتداد الفترة المحددة ضمنها",
        datasets: {
          labels: [],
          datasets: [],
        },
      },

      topicStatistics: {
        id: "topicStatistics",
        content:
          "كيفية توزع المقالات الصحافية بالعدد والنسب ضمن تصنيف أبواب المحتوى الناتج عن عملية البحث (لبنان، العالم العربي، دوليات، رياضة، الخ)",
        datasets: {
          labels: [],
          datasets: [
            {
              backgroundColor: this.pieChartColors,
              data: [],
            },
          ],
        },
      },

      typeStatistics: {
        id: "typeStatistics",
        content:
          "كيفية توزع المقالات الصحافية بالعدد والنسب ضمن تصنيف نوع مقالات المحتوى الناتج عن عملية البحث (الوقائع، الرأي، تحليل اخباري، الخ)",
        datasets: {
          labels: [],
          datasets: [
            {
              backgroundColor: this.pieChartColors,
              data: [],
            },
          ],
        },
      },

      articlesSourceStatistics: {
        id: "articlesSourceStatistics",
        content:
          "كيفية توزع المقالات الصحافية بالعدد والنسب ضمن تصنيف مصادر المحتوى الناتج عن عملية البحث",
        datasets: {
          labels: [],
          datasets: [
            {
              backgroundColor: this.pieChartColors,
              data: [],
            },
          ],
        },
      },

      imagesSourceStatistics: {
        id: "imagesSourceStatistics",
        content:
          "كيفية توزع الصور الفتوغرافية بالعدد والنسب ضمن تصنيف مصادر المحتوى الناتج عن عملية البحث",
        datasets: {
          labels: [],
          datasets: [
            {
              backgroundColor: this.pieChartColors,
              data: [],
            },
          ],
        },
      },

      articlesEntitieswordCloudData: {
        id: "articlesEntitieswordCloudData",
        content:
          "تظهر في هذا الرسم الكلمات المفتاح الواصفة لمضمون المقالات الصحافية والصور الفوتوغرافية الناتجة عن عملية البحث",
        colors: ["#1f77b4", "#629fc9", "#94bedb", "#c9e0ef"],
        words: [],
      },

      articlesAuthorsWordCloudData: {
        id: "articlesAuthorsWordCloudData",
        content:
          "كيفية توزع المقالات الصحافية بالعدد والنسب وفقاً لكتابها المحتوى الناتج عن عملية البحث ",
        colors: ["#1f77b4", "#629fc9", "#94bedb", "#c9e0ef"],
        words: [],
      },

      imagesPhotographersWordCloudData: {
        id: "imagesPhotographersWordCloudData",
        content:
          "كيفية توزع الصور الفوتوغرافية بالعدد والنسب وفقاً لمصوريها المحتوى الناتج عن عملية البحث ",
        colors: ["#1f77b4", "#629fc9", "#94bedb", "#c9e0ef"],
        words: [],
      },

      isArticlesDateChartLoading: false,
      isImagesDateChartLoading: false,
      isTopicChartLoading: false,
      isTypeChartLoading: false,
      isArticlesSourceChartLoading: false,
      isImagesSourceChartLoading: false,
      isArticlesEntitiesChartLoading: false,
      isArticlesAuthorsChartLoading: false,
      isImagesPhotographersChartLoading: false,
    };
  },

  mounted() {
    this.formatPieDataTo20("test");
    this.searchValue = this.$route.query.value;

    var query = { ...this.getBaseQuery };
    delete query["reloadId"];

    if (this.canSearchWithNoValue(query)) {
      var formattedQuery = this.getFormattedQuery(query);
      this.fetchImages(formattedQuery);
      this.fetchArticles(formattedQuery);
      this.SET_SEARCH_VALUE(this.searchValue);

      this.filterChipsData = JSON.parse(JSON.stringify(this.getBaseQuery));
      this.setShowFilterChipsContainer();

      this.setChartsData();
    } else {
      this.$router.push({
        name: "home",
      });
    }
  },

  computed: {
    ...mapGetters("images", ["getSearchResultImages"]),
    ...mapGetters("articles", ["getSearchResultArticles"]),
    ...mapGetters("charts", [
      "getArticlesDateStatistics",
      "getImagesDateStatistics",
      "getArticlesTopicStatistics",
      "getArticlesEntitiesStatistics",
      "getDateStatisticsLabels",
      "getTopicStatisticsLabels",
      "getArticlesTypeStatistics",
      "getTypeStatisticsLabels",
      "getArticlesSourceStatistics",
      "getArticlesSourceStatisticsLabels",
      "getImagesSourceStatistics",
      "getImagesSourceStatisticsLabels",
      "getArticlesAuthorsStatistics",
      "getImagesPhotographersStatistics",
    ]),
    ...mapGetters("search", ["getBaseQuery", "getSearchValue"]),
    ...mapGetters('auth', ['isLoggedIn']),
    ...mapState('auth', ['user']),
    ...mapState("loaders", ["isPageLoading"])
  },

  methods: {
    ...mapActions("articles", ["fetchSearchResultArticles"]),
    ...mapActions("images", ["fetchSearchResultImages"]),
    ...mapActions("charts", [
      "fetchArticlesDateStatistics",
      "fetchImagesDateStatistics",
      "fetchArticlesTopicStatistics",
      "fetchArticlesEntitiesStatistics",
      "fetchArticlesTypeStatistics",
      "fetchArticlesSourceStatistics",
      "fetchImagesSourceStatistics",
      "fetchArticlesAuthorsStatistics",
      "fetchImagesPhotographersStatistics",
    ]),
    ...mapMutations("search", ["SET_SEARCH_VALUE", "SET_BASE_QUERY"]),
    ...mapMutations("loaders", ["SET_IS_PAGE_LOADING"]),
    ...mapMutations('notification', ['notify']),

    async fetchImages(query) {
      this.SET_IS_PAGE_LOADING(true);

      var data = {
        value: this.searchValue,
        query: query,
      };

      try {
        await this.fetchSearchResultImages(data);
        var imagesArray = this.getSearchResultImages;
        this.setImagesButton(imagesArray);
        this.SET_IS_PAGE_LOADING(false);
      } catch(err) {
        this.SET_IS_PAGE_LOADING(false);
        this.showErrorToast();
      }     
    },

    async fetchArticles(query) {
      this.SET_IS_PAGE_LOADING(true);

      try {
        var data = {
          userId: this.user?.id,
          value: this.searchValue,
          query: query,
        };
  
        await this.fetchSearchResultArticles(data);
        var articlesArray = this.getSearchResultArticles;
        this.setArticlesButton(articlesArray);
        this.SET_IS_PAGE_LOADING(false);
      } catch(err) {
        this.SET_IS_PAGE_LOADING(false);
      }
    },

    getFormattedQuery(query) {
      var formattedQuery = "?";

      for (const key in query) {
        if (Object.hasOwnProperty.call(query, key)) {
          if (query[key]) {
            if (Array.isArray(query[key])) {
              for (let i = 0; i < query[key].length; i++) {
                formattedQuery += key + "=" + query[key][i] + "&";
              }
            } else {
              formattedQuery += key + "=" + query[key] + "&";
            }
          }
        }
      }

      formattedQuery = formattedQuery.substring(0, formattedQuery.length - 1);
      return formattedQuery;
    },

    setImagesButton(imagesArray) {
      var imageItems = imagesArray.map((image) => {
        return {
          id: image.id,
          image:
            this.apiUrl + "/entities/images/raw/" + image.fileName + "/400",
          title: image.caption,
          date: image.year + " - " + image.month + " - " + image.day,
        };
      });

      this.imagesButton = {
        id: "photos",
        title: "الصور الفوتوغرافية",
        imageItems: imageItems,
      };
    },

    // setHasNoResults() {
    //     if (this.articlesButton.imageItems.length == 0 && this.imagesButton.imageItems.length) {
    //         this.hasNoResults = true
    //     } else {
    //         this.hasNoResults = false
    //     }
    // },

    showErrorToast() {
      this.notify({
          message: "هناك خطأ ما. يرجى المحاولة مرة أخرى",
          show: true,
        });
  },

    setArticlesButton(articlesArray) {
      var articleItems = articlesArray.map((article) => {
        return {
          id: article.id,
          image:
            this.apiUrl +
            "/entities/images/raw/" +
            (article.newPdfPath && article.newPdfPath.length > 0
              ? article.newPdfPath
              : article.oldPdfPath) +
            "/400",
          title: article.title,
          date: article.year + " - " + article.month + " - " + article.day,
        };
      });

      this.articlesButton = {
        id: "articles",
        title: "التبویب الصحافية",
        imageItems: articleItems,
      };
    },

    showArticlesButton() {
      if (this.articlesButton.imageItems) {
        return this.articlesButton.imageItems.length > 0;
      }
    },

    showImagesButton() {
      if (this.imagesButton.imageItems) {
        return this.imagesButton.imageItems.length > 0;
      }
    },

    goToImageItem(id) {
      this.$router.push({
        name: "images",
        query: this.getBaseQuery,
        hash: "#" + id,
      });
    },

    goToArticleItem(id) {
      this.$router.push({
        name: "articles",
        query: this.getBaseQuery,
        hash: "#" + id,
      });
    },

    goToPage(pageName) {
      this.$router.push({
        name: pageName,
        // params: {
        //     value: this.searchValue
        // },
        query: this.getBaseQuery,
      });
    },

    setShowFilterChipsContainer() {
      delete this.filterChipsData["order"];
      delete this.filterChipsData["pageNumber"];
      delete this.filterChipsData["pageSize"];
      delete this.filterChipsData["sortBy"];

      if (this.filterChipsData) {
        if (
          Object.keys(this.filterChipsData).length === 0 &&
          this.filterChipsData.constructor === Object
        ) {
          this.showFilterChipsContainer = false;
        } else {
          this.showFilterChipsContainer = true;
        }
      } else {
        this.showFilterChipsContainer = false;
      }
    },

    setReloadId() {
      var query = JSON.parse(JSON.stringify(this.getBaseQuery));
      let reloadId =
        Date.now().toString(36) +
        Math.random()
          .toString(36)
          .substring(2);
      query["reloadId"] = reloadId;

      this.SET_BASE_QUERY(query);
    },

    deleteFilterChip(type, filter) {
      this.setReloadId();

      var query = JSON.parse(JSON.stringify(this.getBaseQuery));

      if (typeof query[type] === "string" || query[type] instanceof String) {
        query[type] = "";
      } else if (Array.isArray(query[type])) {
        if (query[type].length == 1) {
          delete query[type];
        } else {
          const index = query[type].indexOf(filter);
          if (index > -1) {
            query[type].splice(index, 1);
          }
        }
      }

      this.SET_BASE_QUERY(query);

      var currentPage = this.$route.name;
      this.goToPage(currentPage);
      this.$router.go();
      window.scrollTo(0, 0);
    },

    setChartDataObject() {
      var query = JSON.parse(JSON.stringify(this.getBaseQuery));
      delete query["reloadId"];
      var formattedQuery = this.getFormattedQuery(query);

      return {
        value: this.getSearchValue,
        query: formattedQuery,
      };
    },

    setChartsData() {
      // this.getDateChartData()
      this.fetchArticlesDateChartData();
      this.fetchImagesDateChartData();
      this.fetchArticlesTopicChartData();
      this.fetchArticlesEntitiesChartData();
      this.fetchArticlesTypeChartData();
      this.fetchArticlesSourceChartData();
      this.fetchImagesSourceChartData();
      this.fetchArticlesAuthorsChartData();
      this.fetchImagesPhotographersChartData();
    },
    // async getDateChartData() {
    //     this.SET_IS_PAGE_LOADING(true)
    //     var data = this.setChartDataObject()

    //     await this.fetchImagesDateStatistics(data)
    //     await this.fetchArticlesDateStatistics(data)

    //     var mergedData = Object.values(this.mergeDateStatistics())

    //     var decimatedData = this.decimateDateChartData(mergedData)

    //     decimatedData.sort((a, b) => (a.year > b.year) ? 1 : -1)

    //     var articlesStats = {
    //         label: 'articles',
    //         borderWidth: 0,
    //         barPercentage: 0.5,
    //         backgroundColor: '#f29100ff',
    //         data: decimatedData.map(item => item.article)
    //     }
    //     var imagesStats = {
    //         label: 'images',
    //         borderWidth: 0,
    //         barPercentage: 0.5,
    //         backgroundColor: '#b5e8fcff',
    //         data: decimatedData.map(item => item.image)
    //     }

    //     this.dateStatistics.datasets.labels = decimatedData.map(item => item.year)
    //     this.dateStatistics.datasets.datasets.push(articlesStats)
    //     this.dateStatistics.datasets.datasets.push(imagesStats)

    //     this.SET_IS_PAGE_LOADING(false)
    // },

    async fetchArticlesDateChartData() {
      this.isArticlesDateChartLoading = true;

      var data = this.setChartDataObject();

      await this.fetchArticlesDateStatistics(data);

      var formattedList = Object.values(
        this.formateDateStatistics(this.getArticlesDateStatistics)
      );
      var decimatedData = this.decimateDateChartData(formattedList);
      decimatedData.sort((a, b) => (a.year > b.year ? 1 : -1));

      var articlesStats = {
        label: "المقالات الصحافية",
        borderWidth: 0,
        barPercentage: 0.5,
        backgroundColor: "#f29100ff",
        data: decimatedData.map((item) => item.image),
      };

      this.articlesDateStatistics.datasets.labels = decimatedData.map(
        (item) => item.year
      );
      this.articlesDateStatistics.datasets.datasets.push(articlesStats);

      this.isArticlesDateChartLoading = false;
    },

    async fetchImagesDateChartData() {
      this.isImagesDateChartLoading = true;

      var data = this.setChartDataObject();

      await this.fetchImagesDateStatistics(data);

      var formattedList = Object.values(
        this.formateDateStatistics(this.getImagesDateStatistics)
      );
      var decimatedData = this.decimateDateChartData(formattedList);
      decimatedData.sort((a, b) => (a.year > b.year ? 1 : -1));

      var imagesStats = {
        label: "الصور الفوتوغرافية",
        borderWidth: 0,
        barPercentage: 0.5,
        backgroundColor: "#b5e8fcff",
        data: decimatedData.map((item) => item.image),
      };

      this.imagesDateStatistics.datasets.labels = decimatedData.map(
        (item) => item.year
      );
      this.imagesDateStatistics.datasets.datasets.push(imagesStats);

      this.isImagesDateChartLoading = false;
    },

    async fetchArticlesTopicChartData() {
      this.isTopicChartLoading = true;

      var data = this.setChartDataObject();
      await this.fetchArticlesTopicStatistics(data);

      var datasets = this.formatPieDataTo20(this.getArticlesTopicStatistics);
      var labels = this.formatPieLabelsTo20(this.getTopicStatisticsLabels);

      this.topicStatistics.datasets.labels = labels;
      this.topicStatistics.datasets.datasets = [
        {
          backgroundColor: this.pieChartColors,
          data: datasets,
        },
      ];

      this.isTopicChartLoading = false;
    },

    async fetchArticlesTypeChartData() {
      this.isTypeChartLoading = true;

      var data = this.setChartDataObject();
      await this.fetchArticlesTypeStatistics(data);

      var datasets = this.formatPieDataTo20(this.getArticlesTypeStatistics);
      var labels = this.formatPieLabelsTo20(this.getTypeStatisticsLabels);

      this.typeStatistics.datasets.labels = labels;
      this.typeStatistics.datasets.datasets = [
        {
          backgroundColor: this.pieChartColors,
          data: datasets,
        },
      ];

      this.isTypeChartLoading = false;
    },

    async fetchArticlesSourceChartData() {
      this.isArticlesSourceChartLoading = true;

      var data = this.setChartDataObject();
      await this.fetchArticlesSourceStatistics(data);

      var datasets = this.formatPieDataTo20(this.getArticlesSourceStatistics);
      var labels = this.formatPieLabelsTo20(
        this.getArticlesSourceStatisticsLabels
      );

      this.articlesSourceStatistics.datasets.labels = labels;
      this.articlesSourceStatistics.datasets.datasets = [
        {
          backgroundColor: this.pieChartColors,
          data: datasets,
        },
      ];

      this.isArticlesSourceChartLoading = false;
    },

    async fetchImagesSourceChartData() {
      this.isImagesSourceChartLoading = true;

      var data = this.setChartDataObject();
      await this.fetchImagesSourceStatistics(data);

      var datasets = this.formatPieDataTo20(this.getImagesSourceStatistics);
      var labels = this.formatPieLabelsTo20(
        this.getImagesSourceStatisticsLabels
      );

      this.imagesSourceStatistics.datasets.labels = labels;
      this.imagesSourceStatistics.datasets.datasets = [
        {
          backgroundColor: this.pieChartColors,
          data: datasets,
        },
      ];

      this.isImagesSourceChartLoading = false;
    },

    async fetchArticlesEntitiesChartData() {
      this.isArticlesEntitiesChartLoading = true;

      var data = this.setChartDataObject();
      await this.fetchArticlesEntitiesStatistics(data);
      this.articlesEntitieswordCloudData.words = JSON.parse(
        JSON.stringify(this.getArticlesEntitiesStatistics)
      );

      this.isArticlesEntitiesChartLoading = false;
    },

    async fetchArticlesAuthorsChartData() {
      this.isArticlesAuthorsChartLoading = true;

      var data = this.setChartDataObject();
      await this.fetchArticlesAuthorsStatistics(data);
      this.articlesAuthorsWordCloudData.words = JSON.parse(
        JSON.stringify(this.getArticlesAuthorsStatistics)
      );

      // this.SET_IS_PAGE_LOADING(false)
      this.isArticlesAuthorsChartLoading = false;
    },

    async fetchImagesPhotographersChartData() {
      this.isImagesPhotographersChartLoading = true;

      var data = this.setChartDataObject();
      await this.fetchImagesPhotographersStatistics(data);
      this.imagesPhotographersWordCloudData.words = JSON.parse(
        JSON.stringify(this.getImagesPhotographersStatistics)
      );

      // this.SET_IS_PAGE_LOADING(false)
      this.isImagesPhotographersChartLoading = false;
    },
  },
};
