import PieChart from '@components/frontend/PieChart';
import NoResults from "@shared/NoResults/NoResults.vue"

export default {
    name:"PieChartDisplay",
    components: {
        PieChart,
        NoResults
    },

    props: {
        data: {
            type: Object,
            required: true,
            default() {return {}}
        },
        isLoading: {
            type: Boolean,
            default: false
        }
    },

    data () {
        return {
            chartData: this.data,
            isDataEmpty: true,

            pieChartWidth: 300,
            pieChartHeight: 270,
            pieChartKey: 0,
            pieChartLegendPlacement: "bottom",
        }
    },

    created() {
        this.setChartSize(window.innerWidth)
        window.addEventListener("resize", this.myEventHandler);
    },

    methods: {
        myEventHandler() {
            if (window.innerWidth) {
                this.setChartSize(window.innerWidth)
            }
            this.pieChartKey++
        },

        setChartSize(windowWidth) {
            if (windowWidth >= 991 ) {
                this.pieChartLegendPlacement = "left"
                this.pieChartWidth = 900
                this.pieChartHeight = 300

            }  else if (windowWidth >= 768) {
                this.pieChartLegendPlacement = "left"
                this.pieChartWidth = 700
                this.pieChartHeight = 300
            }  else if (windowWidth >= 576) {
                this.pieChartLegendPlacement = "bottom"
                this.pieChartWidth = 500
                this.pieChartHeight = 400
            } else {
                this.pieChartLegendPlacement = "bottom"
                this.barChartWidth = 300
                this.barChartHeight = 270
            }
        },

        setIsDataEmpty() {
            try {
                this.isDataEmpty = this.chartData.datasets.datasets[0].data.length > 0 ? false : true
            } 
            catch (error) {
                this.isDataEmpty = true
            }
        }
    },

    watch: {
        data: {
            immediate: true,
            deep: true,
            handler(value) {
                this.chartData = value
                this.setIsDataEmpty()
                this.pieChartKey++
            }
        },
    },

    destroyed() {
        window.removeEventListener("resize", this.myEventHandler);
    },
}