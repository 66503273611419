export default {
    methods: {
        formatPieDataTo20(data) {
            var temp = data
    
            if (data.length > 20) {
                var othersList = []
                var others = 0
    
                temp = data.slice(0, 19)
                othersList = data.slice(19, data.length)
    
                for (let i = 0; i < othersList.length; i++) {
                    others += othersList[i]
                }
    
                temp.push(others)
            }
    
            return temp
        },

        formatPieLabelsTo20(data) {
            var temp = data
            if (data.length > 20) {
                temp = data.slice(0, 19)
                temp.push('الآخرين')
            }
    
            return temp
        },

        mergeDateStatistics() {
            var articlesStats = JSON.parse(JSON.stringify(this.getArticlesDateStatistics));
            var imagesStats = JSON.parse(JSON.stringify(this.getImagesDateStatistics));
            var mergedData = {}

            for (let i = 0; i < articlesStats.length; i++) {
                mergedData[articlesStats[i].label] = {
                    date: articlesStats[i].label,
                    year: articlesStats[i].label.slice(0, 4)
                }
                mergedData[articlesStats[i].label].article = articlesStats[i].value
                mergedData[articlesStats[i].label].image = 0
            }

            for (let i = 0; i < imagesStats.length; i++) {
                if (!Object.hasOwnProperty.call(mergedData, imagesStats[i].label)) {
                    mergedData[imagesStats[i].label] = {
                        date: imagesStats[i].label,
                        year: imagesStats[i].label.slice(0, 4)
                    }
                    mergedData[imagesStats[i].label].article = 0
                }

                mergedData[imagesStats[i].label].image = imagesStats[i].value
            }

            return mergedData
        },

        formateDateStatistics(data) {
            var formattedList = data.map(data => {
                return {
                    date: data.label,
                    year: data.label.slice(0, 4),
                    image: data.value
                }
            })

            return formattedList
        },

        setYearObject(data) {
            var years = {}

            for (let i = 0; i < data.length; i++) {
                if (Object.hasOwnProperty.call(years, data[i]['year'])) {
                    years[data[i]['year']].push(data[i])
                } else {
                    years[data[i]['year']] = []
                    years[data[i]['year']].push(data[i])
                }
            }
            return years
        },

        mergeYearObjectList(years) {
            var yearsList = []

            for (const key in years) {
                var yearData = {year: key, image: 0, article: 0}

                if (Object.hasOwnProperty.call(years, key)) {
                    
                    for (let i = 0; i < years[key].length; i++) {    
                        yearData['image'] += years[key][i].image
                        yearData['article'] += years[key][i].article
                    }
                    
                }
                yearsList.push(yearData)
            }

            return yearsList
        },

        

        decimateDateChartData(data) {

            // var temp = {}

            // for (let i = 0; i < data.length; i++) {
            //     if (Object.hasOwnProperty.call(temp, data[i]['year'])) {
            //         temp[data[i]['year']].push(data[i])
            //     } else {
            //         temp[data[i]['year']] = []
            //         temp[data[i]['year']].push(data[i])
            //     }
            // }

            var years = this.setYearObject(data)

            var yearsList = this.mergeYearObjectList(years)

            // for (const key in temp) {
            //     var yearData = {year: key, image: 0, article: 0}

            //     if (Object.hasOwnProperty.call(temp, key)) {
                    
            //         for (let i = 0; i < temp[key].length; i++) {    
            //             yearData['image'] += temp[key][i].image
            //             yearData['article'] += temp[key][i].article

            
            //         }
                    
            //     }
            //     temp2.push(yearData)
            // }


            return yearsList

        }
    }
}