import BarChart from '@components/frontend/BarChart';
import NoResults from "@shared/NoResults/NoResults.vue"

export default {
    name:"BarChartDisplay",
    components: {
        BarChart,
        NoResults
    },

    props: {
        data: {
            type: Object,
            required: true,
            default() {return {}}
        },
        isLoading: {
            type: Boolean,
            default: false
        }
    },

    data () {
        return {
            chartData: this.data,
            isDataEmpty: true,

            barChartWidth: 300,
            barChartHeight: 270,
            barChartKey: 0
        }
    },

    created() {
        this.setChartSize(window.innerWidth)
        window.addEventListener("resize", this.myEventHandler);
    },

    methods: {
        myEventHandler() {
            if (window.innerWidth) {
                this.setChartSize(window.innerWidth)
            }
            this.barChartKey++
        },

        setChartSize(windowWidth) {
            if (windowWidth >= 991 ) {
                this.barChartWidth = 900
                this.barChartHeight = 300

            }  else if (windowWidth >= 768) {
                this.barChartWidth = 700
                this.barChartHeight = 300
            }  else if (windowWidth >= 576) {
                this.barChartWidth = 500
                this.barChartHeight = 270
            } else {
                this.pieChartWidth = 300
                this.pieChartHeight = 270
            }
        },

        setIsDataEmpty() {
            try {
                this.isDataEmpty = this.chartData.datasets.datasets[0].data.length > 0 ? false : true
            } 
            catch (error) {
                this.isDataEmpty = true
            }
        }
    },

    watch: {
        data: {
            immediate: true,
            deep: true,
            handler(value) {
                this.chartData = value
                this.setIsDataEmpty()
                this.barChartKey++
            }
        },
    },

    destroyed() {
        window.removeEventListener("resize", this.myEventHandler);
    },
}