import WordCloud from '@components/frontend/WordCloud';
import NoResults from "@shared/NoResults/NoResults.vue"

export default {
    name:"WordCloudChartDisplay",
    components: {
        WordCloud,
        NoResults
    },

    props: {
        data: {
            type: Object,
            required: true,
            default() {return {}}
        },
        isLoading: {
            type: Boolean,
            default: false
        }
    },

    data () {
        return {
            chartKey: 0,
            chartData: this.data,
            isDataEmpty: true
        }
    },

    methods: {
        setIsDataEmpty() {
            try {
                this.isDataEmpty = this.chartData.words.length > 0 ? false : true
            } 
            catch (error) {
                this.isDataEmpty = true
            }
        }
    },

    watch: {
        data: {
            immediate: true,
            deep: true,
            handler(value) {
                this.chartData = value
                this.setIsDataEmpty()
            }
        },
    }
}